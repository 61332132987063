import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: {
      "Welcome to React": "Welcome to React and react-i18next",
      "Email Examiner Needed": "Email Examiner Needed",
      "Material Needed": "Material Needed",
      "No Tutor Needed": "No Tutor Needed",
      "No Action Required": "No Action Required",
      Archived: "Archived",
      confirmChange: "Confirm Change",
      discardChange: "Discard Change",
      createExam: "Create Exam",
      createExamOld: "Create Old Exam",
      proposeChange: "Propose Change",
      adminChange: "Admin Change",
      tutorConfirm: "Tutor Confirm",
      tutorDecline: "Tutor Decline",
      confirmChange: "Confirm Change",
      discardChange: "Discard Change",
      "Select a": "Select a {{label}}",
      "Add tutor": "Add tutor",
      "Exam Tutor": "Exam Tutor",
      "Selected Tutor": "Selected Tutor",
      "Registered Tutor(s)": "Registered Tutor(s)",
      "No Tutors assigned themselves yet": "No Tutors assigned themselves yet",
      "First Name": "First Name",
      "Last Name": "Last Name",
      "Exam Title": "Exam Title",
      Duration: "Duration",
      Notes: "Notes",
      Field: "Field",
      min: "min",
      Monday: "Monday",
      Tuesday: "Tuesday",
      Wednesday: "Wednesday",
      Thursday: "Thursday",
      Friday: "Friday",
      Saturday: "Saturday",
      Sunday: "Sunday",
      January: "January",
      February: "February",
      March: "March",
      April: "April",
      May: "May",
      June: "June",
      July: "July",
      August: "August",
      September: "September",
      October: "October",
      November: "November",
      December: "December",
      "Exam Date": "Exam Date",
      "Log Out": "Log Out",
      "Confirm Logout": "Confirm Logout",
      "Are you sure you want to log out of your account?": "Are you sure you want to log out of your account?",
      Cancel: "Cancel",
      "Are you sure?": "Are you sure?",
      Yes: "Yes",
      No: "No",
      Edit: "Edit",
      Delete: "Delete",
      "Confirm delete": "Confirm delete",
      "Cancel delete": "Cancel delete",
      "Add new": "Add new",
      Add: "Add",
      You: "You",
      "will monitor": "will monitor",
      "will no longer monitor": "will no longer monitor",
      "was edited by": "was edited by",
      "Exam was declined by": "Exam was declined by",
      collapse: "collapse",
      expand: "expand",
      "Notification from": "Notification from",
      "click to": "click to",
      details: "details",
      "proposed a new Exam": "proposed a new Exam",
      "Edit notification details": "Edit notification details",
      changed: "changed",
      "proposed an Exam": "proposed an Exam",
      "proposed a change": "proposed a change",
      Tutor: "Tutor",
      "registered to monitor the exam.": "registered to monitor the exam.",
      "canceled to monitor the exam.": "canceled to monitor the exam.",
      "approved the changes.": "approved the changes.",
      "declined the changes.": "declined the changes.",
      "Pagination Navigation": "Pagination Navigation",
      "Go to page": "Go to page",
      Search: "Search",
      "Search items": "Search items",
      "Search...": "Search...",
      "Sort by": "Sort by",
      Status: "Status",
      "Select the status of the exam": "Select the status of the exam",
      "Exam Title": "Exam Title",
      "Date/Time": "Date/Time",
      Student: "Student",
      "LVA Nr.": "LVA Nr.",
      Duration: "Duration",
      Mode: "Mode",
      Examiner: "Examiner",
      Institute: "Institute",
      Notes: "Notes",
      "Student Misc": "Student Misc",
      "Archived Exams": "Archived Exams",
      Edit: "Edit",
      "Error fetching exams": "Error fetching exams",
      "Loading exams...": "Loading exams...",
      "Admin Exam Calendar": "Admin Exam Calendar",
      "Exam Calendar view": "Exam Calendar view",
      Exam: "Exam",
      "starts at": "starts at",
      "and ends at": "and ends at",
      "Week View": "Week View",
      "Day View": "Day View",
      "Month View": "Month View",
      Today: "Today",
      Previous: "Previous",
      Next: "Next",
      Row: "Row",
      Table: "Table",
      table: "table",
      "Color Legend for Exam Statuses": "Color Legend for Exam Statuses",
      "Exam status color legend": "Exam status color legend",
      "Email Examiner Needed": "Email Examiner Needed",
      "Material Needed": "Material Needed",
      "No Room Assigned": "No Room Assigned",
      "No Tutor Needed": "No Tutor Needed",
      "No Tutors Assigned": "No Tutors Assigned",
      "No Tutor Picked Yet": "No Tutor Picked Yet",
      "No Action Required": "No Action Required",
      "Data Administration": "Data Administration",
      ID: "ID",
      Actions: "Actions",
      "N/A": "N/A",
      "Loading...": "Loading...",
      "Invalid API response": "Invalid API response",
      "Error fetching data": "Error fetching data",
      "Invalid response format for": "Invalid response format for",
      "Error fetching relational data": "Error fetching relational data",
      "Error adding record": "Error adding record",
      "Error updating record": "Error updating record",
      "Error deleting record": "Error deleting record",
      "Edit record": "Edit record",
      "Delete record": "Delete record",
      "Confirm Deletion": "Confirm Deletion",
      "Are you sure you want to delete this record? This action cannot be undone.":
        "Are you sure you want to delete this record? This action cannot be undone.",
      "Cancel deletion": "Cancel deletion",
      "Confirm deletion": "Confirm deletion",
      Confirm: "Confirm",
      "Add Record": "Add Record",
      Back: "Back",
      "Go back to Data Administration menu": "Go back to Data Administration menu",
      "Unknown Table": "Unknown Table",
      Examiners: "Examiners",
      Exams: "Exams",
      "Exam modes": "Exam modes",
      Institutes: "Institutes",
      Majors: "Majors",
      Rooms: "Rooms",
      Users: "Users",
      "Exam Editor": "Exam Editor",
      "LVA Num": "LVA Number",
      "LVA course Number": "LVA Course Number",
      "Exam Date and Time": "Exam Date and Time",
      "Exam Duration": "Exam Duration",
      "Exam Student": "Exam Student",
      "Exam Tutor": "Exam Tutor",
      "Course Examiner": "Course Examiner",
      "Course Major": "Course Major",
      "Course Institute": "Course Institute",
      "Exam Mode": "Exam Mode",
      "Exam Notes": "Exam Notes",
      Duration: "Duration",
      Tutors: "Tutors",
      Status: "Status",
      Major: "Major",
      Room: "Room",
      Pending: "Pending",
      "Search student...": "Search student...",
      "Search tutors...": "Search tutors...",
      "Search examiner...": "Search examiner...",
      "Search majors...": "Search majors...",
      "Search institutes...": "Search institutes...",
      "Search modes...": "Search modes...",
      "Search rooms...": "Search rooms...",
      "Room Selector": "Room Selector",
      "Back to previous page": "Back to previous page",
      Save: "Save",
      "Save changes": "Save changes",
      "Edit exam": "Edit exam",
      "Cancel editing": "Cancel editing",
      "Confirm Room Selection": "Confirm Room Selection",
      "The selected room has a capacity of 0. Do you want to continue?": "The selected room has a capacity of 0. Do you want to continue?",
      "Cancel room selection": "Cancel room selection",
      "Loading exam data...": "Loading exam data...",
      "Room capacity exceeded with overlapping exams!": "Room capacity exceeded with overlapping exams!",
      "Room capacity": "Room capacity",
      "Room with 0 capacity selected": "Room with 0 capacity selected",
      "Room selection canceled": "Room selection canceled",
      "Error fetching exam data": "Error fetching exam data",
      "Error fetching dropdown options": "Error fetching dropdown options",
      "Error updating exam": "Error updating exam",
      "Error submitting notification": "Error submitting notification",
      "Exam updated successfully!": "Exam updated successfully!",
      "No exam data found": "No exam data found",
      "Room Management": "Room Management",
      mins: "mins",
      "Please select a room to save.": "Please select a room to save.",
      "Room not found": "Room not found",
      "Failed to update exam room": "Failed to update exam room",
      "Error updating exam room": "Error updating exam room",
      "Select Room for Exam": "Select Room for Exam",
      "Save Room Selection": "Save Room Selection",
      Unknown: "Unknown",
      "Select a Room to View Calendar": "Select a Room to View Calendar",
      "Error fetching": "Error fetching",
      "Failed to fetch exams": "Failed to fetch exams",
      "Failed to fetch rooms": "Failed to fetch rooms",
      "Failed to fetch": "Failed to fetch",
      View: "View",
      "HTTP error!": "HTTP error!",
      Message: "Message",
      "Unknown error": "Unknown error",
      "Request Exam Monitoring": "Request Exam Monitoring",
      Request: "Request",
      "Proposed Changes": "Proposed Changes",
      "Comparison Fields": "Comparison Fields",
      Title: "Title",
      Date: "Date",
      "Accept proposed changes": "Accept proposed changes",
      "Discard proposed changes": "Discard proposed changes",
      "Error fetching Notification": "Error fetching Notification",
      "Exam Changes": "Exam Changes",
      Old: "Old",
      New: "New",
      "Exam updated successfully": "Exam updated successfully",
      "The following changes have been made to the exam": "The following changes have been made to the exam",
      "Exam Update Notification": "Exam Update Notification",
      "The exam has been updated successfully.": "The exam has been updated successfully.",
      "New Exam Not Found": "New Exam Not Found",
      "The new exam has been accepted": "The new exam has been accepted",
      "Exam Accepted Notification": "Exam Accepted Notification",
      "The exam has been accepted.": "The exam has been accepted.",
      "Exam Decline Notification": "Exam Decline Notification",
      "The exam has been declined.": "The exam has been declined.",
      "We regret to inform you that the proposed exam has been <strong>declined</strong>. Below are the details of the declined exam:":
        "We regret to inform you that the proposed exam has been <strong>declined</strong>. Below are the details of the declined exam:",
      "We regret to inform you that the proposed exam changes has been <strong>declined</strong>. Below are the current details of the exam:":
        "We regret to inform you that the proposed exam changes has been <strong>declined</strong>. Below are the current details of the exam:",
      "Exam Change Decline Notification": "Exam Change Decline Notification",
      "The exam changes has been declined.": "The exam changes has been declined.",
      "Error fetching student data": "Error fetching student data",
      "Error fetching majors.": "Error fetching majors.",
      "student record has been updated successfully.": "student record has been updated successfully.",
      "Error updating the student record": "Error updating the student record",
      "Loading student data...": "Loading student data...",
      "Student Details": "Student Details",
      "Edit student's first name": "Edit student's first name",
      "Edit student's last name": "Edit student's last name",
      Phone: "Phone",
      "Edit student's phone": "Edit student's phone",
      "Matrikel Number": "Matrikel Number",
      "Edit student's matrikel number": "Edit student's matrikel number",
      "Search major...": "Search major...",
      "Select student's major": "Select student's major",
      "Emergency Contact": "Emergency Contact",
      "Edit student's emergency contact": "Edit student's emergency contact",
      Overtime: "Overtime",
      "Edit student's overtime allowance": "Edit student's overtime allowance",
      Misc: "Misc",
      "Edit miscellaneous student details": "Edit miscellaneous student details",
      "Go back to the previous page": "Go back to the previous page",
      "Enable edit mode": "Enable edit mode",
      "Error fetching tutor data": "Error fetching tutor data",
      "tutor record has been updated successfully.": "tutor record has been updated successfully.",
      "Error updating the tutor record": "Error updating the tutor record",
      "Loading tutor data...": "Loading tutor data...",
      "Tutor Details": "Tutor Details",
      "Edit tutor's first name": "Edit tutor's first name",
      "Edit tutor's last name": "Edit tutor's last name",
      "Edit tutor's phone": "Edit tutor's phone",
      "Edit tutor's matrikel number": "Edit tutor's matrikel number",
      Course: "Course",
      "Edit tutor's course": "Edit tutor's course",
      "Error fetching notifications": "Error fetching notifications",
      "Loading notifications...": "Loading notifications...",
      Notifications: "Notifications",
      "Proposed Exams": "Proposed Exams",
      "Required Actions": "Required Actions",
      "New Notifications": "New Notifications",
      "Old Notifications": "Old Notifications",
      "Are you sure you want to remove the exam from the monitoring list?": "Are you sure you want to remove the exam from the monitoring list?",
      "Remove exam from monitoring submitted successfully": "Remove exam from monitoring submitted successfully",
      "Error submitting request to remove monitoring exam": "Error submitting request to remove monitoring exam",
      "No exam details found.": "No exam details found.",
      "Exam Details": "Exam Details",
      minutes: "minutes",
      "Not Assigned": "Not Assigned",
      Remove: "Remove",
      "Confirm Removal": "Confirm Removal",
      "Please fill in the following fields": "Please fill in the following fields",
      "Exam notification created successfully": "Exam notification created successfully",
      "Error creating exam notification": "Error creating exam notification",
      "Request an Exam": "Request an Exam",
      "Add Exam Title": "Add Exam Title",
      "Add Exam LVA Num": "Add Exam LVA Num",
      "Add Exam Date and Time": "Add Exam Date and Time",
      "Add Exam Duration in minutes": "Add Exam Duration in minutes",
      "Add Exam Examiner": "Add Exam Examiner",
      "Add Exam Institute": "Add Exam Institute",
      "Add Exam Mode": "Add Exam Mode",
      "Add Exam Notes": "Add Exam Notes",
      "Submit Request": "Submit Request",
      "Cancel Request": "Cancel Request",
      "Rooms Calendar": "Rooms Calendar",
      "Room management Calendar": "Room management Calendar",
      "Student Exam Calendar": "Student Exam Calendar",
      "Tutor Exam Calendar": "Tutor Exam Calendar",
      "scheduled for": "scheduled for",
      "Bonus Time": "Bonus Time",
      "Error fetching students": "Error fetching students",
      "Loading students...": "Loading students...",
      "Students List": "Students List",
      "Database Table Navigation": "Database Table Navigation",
      "Database Tables": "Database Tables",
      "Navigate to": "Navigate to",
      "Request to Monitor Exam": "Request to Monitor Exam",
      "Request to Monitor": "Request to Monitor",
      "Request to monitor exam submitted successfully": "Request to monitor exam submitted successfully",
      "Error submitting request to monitor exam": "Error submitting request to monitor exam",
      "Upcoming Monitored Exams": "Upcoming Monitored Exams",
      "Tutor's Upcoming Monitored Exams": "Tutor's Upcoming Monitored Exams",
      "List of Registered Exams": "List of Registered Exams",
      Deregister: "Deregister",
      "Are you sure you want to deregister from this exam": "Are you sure you want to deregister from this exam",
      "Error fetching tutors": "Error fetching tutors",
      "Loading Tutors...": "Loading Tutors...",
      "Tutors List": "Tutors List",
      "Exam View": "Exam View",
      "Student View": "Student View",
      "Tutor View": "Tutor View",
      "Main Information Box": "Main Information Box",
      "Admin Layout": "Admin Layout",
      "Application Header": "Application Header",
      "Side Menu": "Side Menu",
      "Logout from the application": "Logout from the application",
      "Main Content Area": "Main Content Area",
      "Calendar View": "Calendar View",
      "Switch to previous month": "Switch to previous month",
      Prev: "Prev",
      "Switch to next month": "Switch to next month",
      "Monthly Calendar": "Monthly Calendar",
      Day: "Day",
      "Empty cell": "Empty cell",
      "Page Header": "Page Header",
      "IIS Exam Page Title": "IIS Exam Page Title",
      "IIS Exam Page": "IIS Exam Page",
      "Language Switch": "Language Switch",
      "Logged in username": "Logged in username",
      "Switch to German language": "Switch to German language",
      "Switch to English language": "Switch to English language",
      "Current language: English": "Current language: English",
      "Current language: German": "Current language: German",
      "Notification bell": "Notification bell",
      "Notifications list": "Notifications list",
      Notification: "Notification",
      "proposed changes for": "proposed changes for",
      "approved changes in": "approved changes in",
      "rejected changes in": "rejected changes in",
      "added a new Exam": "added a new Exam",
      "No new notifications": "No new notifications",
      "Notifications button": "Notifications button",
      "unread notifications": "unread notifications",
      "The following changes have been made": "The following changes have been made",
      "The following exam details have been updated.": "The following exam details have been updated.",
      "Error sending emails": "Error sending emails",
      "Add New Record": "Add New Record",
      "Update Record": "Update Record",
      Update: "Update",
      "Cancel form": "Cancel form",
      "Exam Overview": "Exam Overview",
      Students: "Students",
      "Exams Overview": "Exams Overview",
      "Request New Exam": "Request New Exam",
      "Go to Exam Overview": "Go to Exam Overview",
      "Go to Archived Exam Overview": "Go to Archived Exam Overview",
      "Go to Students Management": "Go to Students Management",
      "Go to Tutors Management": "Go to Tutors Management",
      "Go to Data Administration": "Go to Data Administration",
      "Go to Room Management": "Go to Room Management",
      Calendar: "Calendar",
      "Student Layout": "Student Layout",
      "Tutor Layout": "Tutor Layout",
      "Go to Request New Exam": "Go to Request New Exam",
      "Go to Upcoming Monitored Exams": "Go to Upcoming Monitored Exams",
      "Upcoming Confirmed Exams": "Upcoming Confirmed Exams",
      "Go to Upcoming/Registered Exams": "Go to Upcoming/Registered Exams",
      "Registered Exams": "Registered Exams",
      "Go to Request Exam Monitoring": "Go to Request Exam Monitoring",
      "Vertical Calendar": "Vertical Calendar",
      "Admin dashboard main content": "Admin dashboard main content",
      "Exam Management Application": "Exam Management Application",
      "Application Root": "Application Root",
      "Failed to send email": "Failed to send email",
      Login: "Login",
      "Submit Login Form": "Submit Login Form",
      Password: "Password",
      Email: "Email",
      "Login Form": "Login Form",
      "Server can't be reached": "Server can't be reached",
      "Username or Password don't match.": "Username or Password don't match.",
    },
  },
  de: {
    translation: {
      Yes: "Ja",
      No: "Nein",
      "Are you sure?": "Bist du sicher?",
      Students: "Studenten",
      "Upcoming Exams": "Bevorstehende Prüfungen",
      "Upcoming Monitored Exams": "Eingetragene Prüfungen",
      "Request New Exam": "Prüfung Anfragen",
      "Exams Overview": "Prüfungsübersicht",
      Tutors: "Tutoren",
      "First Name": "Vorname",
      "Last Name": "Nachname",
      Phone: "Telefonnummer",
      "Emergency Contacty": "Notfallnummer",
      "Registration Number": "Martrikelnummer",
      "Bonus Time": "Extra Zeit",
      Course: "Studiengang",
      Date: "Datum",
      Topic: "Kurs",
      Username: "Benutzername",
      Password: "Passwort",
      "Exam Overview": "Kursübersicht",
      "Emergency Contact": "Notfallkontakt",
      Duration: "Dauer",
      "Date/Time": "Datum/Zeit",
      Examiner: "Prüfer",
      Major: "Abschluss",
      Institute: "Institut",
      Mode: "Modus",
      Room: "Raum",
      Save: "Speichern",
      Edit: "Bearbeiten",
      "Search student...": "Suche Student*in",
      "Search tutors...": "Suche Tutor*in",
      "Search examiner...": "Suche Prüfer*in",
      "Search majors...": "Suche Abschluss",
      "Search institutes...": "Suche Institut",
      "Search modes...": "Suche Modus",
      "Search rooms...": "Suche Raum",
      "Matrikel Number": "Matrikelnummer",
      "Required Actions": "Erforderliche Aktionen",
      Notifications: "Benachrichtigungen",
      "New Notifications": "Neue Benachrichtigungen",
      "Old Notifications": "Alte Benachrichtigungen",
      "Proposed Changes": "Vorgeschlagene Veränderungen",
      "Proposed Exams": "Vorgeschlagene Prüfungen",
      "Confirm Logout": "Ausloggen Bestätigen",
      "Are you sure you want to log out of your account?": "Bist du sicher dass du dich ausloggen möchtest?",
      "Are you sure you want to remove the exam from the monitoring list?": "Bist du sicher dass du die Prüfung nicht mehr betreuen möchtest?",
      "Request to Monitor": "Betreuung Eintragen",
      "Email Examiner Needed": "E-Mail des Prüfers erforderlich",
      "Material Needed": "Material benötigt",
      "No Tutor Needed": "Kein Tutor erforderlich",
      "No Action Required": "Keine Aktion erforderlich",
      Archived: "Archiviert",
      /*
      confirmChange: "Änderung Bestätigen",
      discardChange: "Änderung Verwerfen",
      createExam: "Prüfung Erstellen",
      createExamOld: "Alte Prüfung Erstellen",
      proposeChange: "Änderung Vorschlagen",
      adminChange: "Admin Änderung",
      tutorConfirm: "Tutor Bestätigen",
      tutorDecline: "Tutor Ablehnen",
      confirmChange: "Änderung Bestätigen",
      discardChange: "Änderung Verwerfen", */
      "Enter date": "Datum eingeben",
      "Enter time": "Zeit eingeben",
      "Selected date and time": "Ausgewähltes Datum und Zeit",
      "Select a": "Wählen Sie ein(e)",
      "Add tutor": "Tutor hinzufügen",
      "Exam Tutor": "Prüfer",
      "Selected Tutor": "Ausgewählter Tutor",
      "Registered Tutor(s)": "Registrierte Tutoren",
      "No Tutors assigned themselves yet": "Keine Tutoren haben sich bisher zugewiesen",
      "First Name": "Vorname",
      "Last Name": "Nachname",
      "Exam Title": "Klausur Name",
      Duration: "Dauer",
      Notes: "Notizen",
      Field: "Feld",
      min: "Min",
      Monday: "Montag",
      Tuesday: "Dienstag",
      Wednesday: "Mittwoch",
      Thursday: "Donnerstag",
      Friday: "Freitag",
      Saturday: "Samstag",
      Sunday: "Sonntag",
      January: "Januar",
      February: "Februar",
      March: "März",
      April: "April",
      May: "Mai",
      June: "Juni",
      July: "Juli",
      August: "August",
      September: "September",
      October: "Oktober",
      November: "November",
      December: "Dezember",
      "Exam Date": "Prüfungsdatum",
      "Log Out": "Abmelden",
      "Confirm Logout": "Abmeldung bestätigen",
      "Are you sure you want to log out of your account?": "Sind Sie sicher, dass Sie sich von Ihrem Konto abmelden möchten?",
      Cancel: "Abbrechen",
      Delete: "Löschen",
      "Confirm delete": "Löschen bestätigen",
      "Cancel delete": "Löschen abbrechen",
      "Add new": "Neue hinzufügen",
      Add: "Hinzufügen",
      You: "(Dir)",
      At: "bei",
      "will monitor": "betreut",
      "will no longer monitor": "wird nicht mehr überwachen",
      "was edited by": "wurde bearbeitet von",
      "Exam was declined by": "Prüfung wurde abgelehnt von",
      collapse: "einklappen",
      expand: "ausklappen",
      "Notification from": "Benachrichtigung von",
      "click to": "klicken Sie, um",
      details: "Details",
      "proposed a new Exam": "hat eine neue Prüfung vorgeschlagen",
      "Edit notification details": "Benachrichtigungsdetails bearbeiten",
      changed: "geändert",
      "proposed an Exam": "hat eine Prüfung vorgeschlagen",
      "proposed a change": "hat eine Änderung vorgeschlagen",
      Tutor: "Tutor",
      "registered to monitor the exam.": "hat sich zur Betreuung der Prüfung registriert.",
      "canceled to monitor the exam.": "hat die Betreuung der Prüfung abgebrochen.",
      "approved the changes.": "hat die Änderungen genehmigt.",
      "declined the changes.": "hat die Änderungen abgelehnt.",
      "Pagination Navigation": "Seitennavigation",
      "Go to page": "Gehe zu Seite",
      Search: "Suchen",
      "Search items": "Elemente suchen",
      "Search...": "Suchen...",
      "Sort by": "Sortieren nach",
      Status: "Status",
      "Select the status of the exam": "Wählen Sie den Status der Prüfung",
      "Exam Title": "Prüfungstitel",
      Student: "Student",
      "LVA Nr.": "LVA Nr.",
      Mode: "Modus",
      Notes: "Notizen",
      "Student Misc": "Studenten-Misc",
      "Archived Exams": "Archivierte Prüfungen",
      Edit: "Bearbeiten",
      "Error fetching exams": "Fehler beim Abrufen der Prüfungen",
      "Loading exams...": "Prüfungen werden geladen...",
      "Admin Exam Calendar": "Admin-Prüfungskalender",
      "Exam Calendar view": "Prüfungskalenderansicht",
      Exam: "Prüfung",
      "starts at": "beginnt um",
      "and ends at": "und endet um",
      "Week View": "Wochenansicht",
      "Day View": "Tagesansicht",
      "Month View": "Monatsansicht",
      Today: "Heute",
      Previous: "Vorherige",
      Next: "Nächste",
      Row: "Linie",
      Table: "Tabelle",
      table: "tabelle",
      "Color Legend for Exam Statuses": "Farblegende für Prüfungsstatus",
      "Exam status color legend": "Legende des Prüfungsstatus",
      "Email Examiner Needed": "E-Mail des Prüfers benötigt",
      "Material Needed": "Material benötigt",
      "No Room Assigned": "Kein Raum zugewiesen",
      "No Tutor Needed": "Kein Tutor benötigt",
      "No Tutors Assigned": "Keine Tutoren zugewiesen",
      "No Tutor Picked Yet": "Noch kein Tutor ausgewählt",
      "No Action Required": "Keine Aktion erforderlich",
      "Data Administration": "Datenverwaltung",
      ID: "ID",
      Actions: "Aktionen",
      "N/A": "N/V",
      "Loading...": "Laden...",
      "Invalid API response": "Ungültige API-Antwort",
      "Error fetching data": "Fehler beim Abrufen der Daten",
      "Invalid response format for": "Ungültiges Antwortformat für",
      "Error fetching relational data": "Fehler beim Abrufen der relationalen Daten",
      "Error adding record": "Fehler beim Hinzufügen des Datensatzes",
      "Error updating record": "Fehler beim Aktualisieren des Datensatzes",
      "Error deleting record": "Fehler beim Löschen des Datensatzes",
      "Edit record": "Datensatz bearbeiten",
      "Delete record": "Datensatz löschen",
      "Confirm Deletion": "Löschung bestätigen",
      "Are you sure you want to delete this record? This action cannot be undone.":
        "Sind Sie sicher, dass Sie diesen Datensatz löschen möchten? Diese Aktion kann nicht rückgängig gemacht werden.",
      "Cancel deletion": "Löschung abbrechen",
      "Confirm deletion": "Löschung bestätigen",
      Confirm: "Bestätigen",
      "Add Record": "Datensatz hinzufügen",
      Back: "Zurück",
      "Go back to Data Administration menu": "Zurück zum Datenverwaltungsmenü",
      "Unknown Table": "Unbekannte Tabelle",
      Examiners: "Prüfer",
      Exams: "Prüfungen",
      "Exam modes": "Prüfungsmodi",
      Institutes: "Institute",
      Majors: "Studiengänge",
      Rooms: "Räume",
      Users: "Benutzer",
      "Exam Editor": "Prüfungseditor",
      "LVA Num": "LVA Nummer",
      "LVA course Number": "LVA Kursnummer",
      "Exam Date and Time": "Prüfungsdatum und -zeit",
      "Exam Duration": "Prüfungsdauer",
      "Exam Student": "Prüfungsstudent",
      "Exam Tutor": "Prüfungsbetreuer",
      "Course Examiner": "Kursprüfer",
      "Course Major": "Studienrichtung",
      "Course Institute": "Kursinstitut",
      "Exam Mode": "Prüfungsmodus",
      "Exam Notes": "Prüfungsnotizen",
      Duration: "Dauer",
      Tutors: "Betreuer",
      Status: "Status",
      Major: "Studiengang",
      Room: "Raum",
      Pending: "Ausstehend",
      "Room Selector": "Raumauswahl",
      "Back to previous page": "Zurück zur vorherigen Seite",
      Save: "Speichern",
      "Save changes": "Änderungen speichern",
      "Edit exam": "Prüfung bearbeiten",
      "Cancel editing": "Bearbeitung abbrechen",
      "Confirm Room Selection": "Raumauswahl bestätigen",
      "The selected room has a capacity of 0. Do you want to continue?": "Der ausgewählte Raum hat eine Kapazität von 0. Möchten Sie fortfahren?",
      "Cancel room selection": "Raumauswahl abbrechen",
      "Loading exam data...": "Prüfungsdaten werden geladen...",
      "Room capacity exceeded with overlapping exams!": "Raumkapazität durch überlappende Prüfungen überschritten!",
      "Room capacity": "Raumkapazität",
      "Room with 0 capacity selected": "Raum mit Kapazität 0 ausgewählt",
      "Room selection canceled": "Raumauswahl abgebrochen",
      "Error fetching exam data": "Fehler beim Abrufen der Prüfungsdaten",
      "Error fetching dropdown options": "Fehler beim Abrufen der Dropdown-Optionen",
      "Error updating exam": "Fehler beim Aktualisieren der Prüfung",
      "Error submitting notification": "Fehler beim Senden der Benachrichtigung",
      "Exam updated successfully!": "Prüfung erfolgreich aktualisiert!",
      "No exam data found": "Keine Prüfungsdaten gefunden",
      "Room Management": "Raumverwaltung",
      mins: "Minuten",
      "Please select a room to save.": "Bitte wählen Sie einen Raum aus, um zu speichern.",
      "Room not found": "Raum nicht gefunden",
      "Failed to update exam room": "Fehler beim Aktualisieren des Prüfungsraums",
      "Error updating exam room": "Fehler beim Aktualisieren des Prüfungsraums",
      "Select Room for Exam": "Raum für Prüfung auswählen",
      "Save Room Selection": "Raumauswahl speichern",
      Unknown: "Unbekannt",
      "Select a Room to View Calendar": "Wählen Sie einen Raum, um den Kalender anzuzeigen",
      "Error fetching": "Fehler beim Abrufen",
      "Failed to fetch exams": "Fehler beim Abrufen der Prüfungen",
      "Failed to fetch rooms": "Fehler beim Abrufen der Räume",
      "Failed to fetch": "Fehler beim Abrufen",
      View: "Ansehen",
      "HTTP error!": "HTTP-Fehler!",
      Message: "Nachricht",
      "Unknown error": "Unbekannter Fehler",
      "Request Exam Monitoring": "Prüfungsüberwachung anfordern",
      Request: "Anfrage",
      "Proposed Changes": "Vorgeschlagene Änderungen",
      "Comparison Fields": "Vergleichsfelder",
      Title: "Titel",
      Date: "Datum",
      "Accept proposed changes": "Vorgeschlagene Änderungen akzeptieren",
      "Discard proposed changes": "Vorgeschlagene Änderungen verwerfen",
      "Error fetching Notification": "Fehler beim Abrufen der Benachrichtigung",
      "Exam Changes": "Prüfungsänderungen",
      Old: "Alt",
      New: "Neu",
      "Exam updated successfully": "Prüfung erfolgreich aktualisiert",
      "The following changes have been made to the exam": "Die folgenden Änderungen wurden an der Prüfung vorgenommen",
      "Exam Update Notification": "Prüfungsaktualisierungsbenachrichtigung",
      "The exam has been updated successfully.": "Die Prüfung wurde erfolgreich aktualisiert.",
      "New Exam Not Found": "Neue Prüfung nicht gefunden",
      "The new exam has been accepted": "Die neue Prüfung wurde akzeptiert",
      "Exam Accepted Notification": "Prüfungsakzeptanzbenachrichtigung",
      "The exam has been accepted.": "Die Prüfung wurde akzeptiert.",
      "Exam Decline Notification": "Prüfungsablehnungsbenachrichtigung",
      "The exam has been declined.": "Die Prüfung wurde abgelehnt.",
      "We regret to inform you that the proposed exam has been <strong>declined</strong>. Below are the details of the declined exam:":
        "Wir bedauern, Ihnen mitteilen zu müssen, dass die vorgeschlagene Prüfung <strong>abgelehnt</strong> wurde. Nachfolgend finden Sie die Details der abgelehnten Prüfung:",
      "We regret to inform you that the proposed exam changes has been <strong>declined</strong>. Below are the current details of the exam:":
        "Wir bedauern, Ihnen mitteilen zu müssen, dass die vorgeschlagenen Prüfungsänderungen <strong>abgelehnt</strong> wurden. Nachfolgend finden Sie die aktuellen Details der Prüfung:",
      "Exam Change Decline Notification": "Benachrichtigung über die Ablehnung von Prüfungsänderungen",
      "The exam changes has been declined.": "Die Prüfungsänderungen wurden abgelehnt.",
      "Error fetching student data": "Fehler beim Abrufen der Studentendaten",
      "Error fetching majors.": "Fehler beim Abrufen der Studiengänge.",
      "student record has been updated successfully.": "Die Studentendaten wurden erfolgreich aktualisiert.",
      "Error updating the student record": "Fehler beim Aktualisieren der Studentendaten",
      "Loading student data...": "Studentendaten werden geladen...",
      "Student Details": "Studentendetails",
      "Edit student's first name": "Vornamen des Studenten bearbeiten",
      "Edit student's last name": "Nachnamen des Studenten bearbeiten",
      Phone: "Telefon",
      "Edit student's phone": "Telefonnummer des Studenten bearbeiten",
      "Edit student's matrikel number": "Matrikelnummer des Studenten bearbeiten",
      "Search major...": "Studiengang suchen...",
      "Select student's major": "Studiengang des Studenten auswählen",
      "Edit student's emergency contact": "Notfallkontakt des Studenten bearbeiten",
      Overtime: "Mehrzeit",
      "Edit student's overtime allowance": "Überstundenregelung des Studenten bearbeiten",
      Misc: "Sonstiges",
      "Edit miscellaneous student details": "Sonstige Studentendetails bearbeiten",
      "Go back to the previous page": "Zurück zur vorherigen Seite",
      "Enable edit mode": "Bearbeitungsmodus aktivieren",
      "Error fetching tutor data": "Fehler beim Abrufen der Tutordaten",
      "tutor record has been updated successfully.": "Die Tutordaten wurden erfolgreich aktualisiert.",
      "Error updating the tutor record": "Fehler beim Aktualisieren der Tutordaten",
      "Loading tutor data...": "Tutordaten werden geladen...",
      "Tutor Details": "Tutordetails",
      "Edit tutor first name": "Vornamen des Tutors bearbeiten",
      "Edit tutor last name": "Nachnamen des Tutors bearbeiten",
      "Edit tutor phone": "Telefonnummer des Tutors bearbeiten",
      "Edit tutor matrikel number": "Matrikelnummer des Tutors bearbeiten",
      Course: "Kurs",
      "Edit tutor's course": "Kurs des Tutors bearbeiten",
      "Error fetching notifications": "Fehler beim Abrufen der Benachrichtigungen",
      "Error fetching dropdown options": "Fehler beim Abrufen der Dropdown-Optionen",
      "Loading notifications...": "Lade Benachrichtigungen...",
      "Remove exam from monitoring submitted successfully": "Prüfung erfolgreich aus der Betreuung entfernt",
      "Error submitting request to remove monitoring exam": "Fehler beim Senden der Anfrage zur Entfernung der Prüfungsbetreuung",
      "No exam details found.": "Keine Prüfungsdetails gefunden.",
      "Exam Details": "Prüfungsdetails",
      minutes: "Minuten",
      "Not Assigned": "Nicht zugewiesen",
      Remove: "Entfernen",
      "Confirm Removal": "Entfernung bestätigen",
      "Please fill in the following fields": "Bitte füllen Sie die folgenden Felder aus",
      "Exam notification created successfully": "Prüfungsbenachrichtigung erfolgreich erstellt",
      "Error creating exam notification": "Fehler beim Erstellen der Prüfungsbenachrichtigung",
      "Request an Exam": "Prüfung anfordern",
      "Add Exam Title": "Prüfungstitel hinzufügen",
      "Add Exam LVA Num": "Prüfungs-LVA-Nummer hinzufügen",
      "Add Exam Date and Time": "Prüfungsdatum und -zeit hinzufügen",
      "Add Exam Duration in minutes": "Prüfungsdauer in Minuten hinzufügen",
      "Add Exam Examiner": "Prüfer hinzufügen",
      "Add Exam Institute": "Prüfungsinstitut hinzufügen",
      "Add Exam Mode": "Prüfungsmodus hinzufügen",
      "Add Exam Notes": "Prüfungsnotizen hinzufügen",
      "Submit Request": "Anfrage senden",
      "Cancel Request": "Anfrage abbrechen",
      "Rooms Calendar": "Raumkalender",
      "Room management Calendar": "Raumverwaltungs-Kalender",
      "Student Exam Calendar": "Studenten-Prüfungskalender",
      "Tutor Exam Calendar": "Tutor-Prüfungskalender",
      "scheduled for": "geplant für",
      "Error fetching students": "Fehler beim Abrufen der Studenten",
      "Loading students...": "Studenten werden geladen...",
      "Students List": "Studentenliste",
      "Database Table Navigation": "Datenbanktabellen-Navigation",
      "Database Tables": "Datenbanktabellen",
      "Navigate to": "Navigieren zu",
      "Request to Monitor Exam": "Anfrage zur Betreuung der Prüfung",
      "Request to Monitor": "Betreuunganfrage",
      "Request to monitor exam submitted successfully": "Anfrage zur Betreuung der Prüfung erfolgreich eingereicht",
      "Error submitting request to monitor exam": "Fehler beim Einreichen der Betreuunganfrage für die Prüfung",
      "Tutor's Upcoming Monitored Exams": "Bevorstehende betreute Prüfungen des Tutors",
      "List of Registered Exams": "Liste der registrierten Prüfungen",
      Deregister: "Abmelden",
      "Are you sure you want to deregister from this exam": "Sind Sie sicher, dass Sie sich von dieser Prüfung abmelden möchten",
      "Error fetching tutors": "Fehler beim Abrufen der Tutoren",
      "Loading Tutors...": "Tutoren werden geladen...",
      "Tutors List": "Tutorenliste",
      "Exam View": "Prüfungsansicht",
      "Student View": "Studentenansicht",
      "Tutor View": "Tutorenansicht",
      "Main Information Box": "Hauptinformationsfeld",
      "Admin Layout": "Admin-Layout",
      "Application Header": "Anwendungskopfzeile",
      "Side Menu": "Seitenmenü",
      "Logout from the application": "Abmeldung von der Anwendung",
      "Main Content Area": "Hauptinhaltsbereich",
      "Calendar View": "Kalenderansicht",
      "Switch to previous month": "Zum vorherigen Monat wechseln",
      Prev: "Zurück",
      "Switch to next month": "Zum nächsten Monat wechseln",
      "Monthly Calendar": "Monatskalender",
      Day: "Tag",
      "Empty cell": "Leere Zelle",
      "Page Header": "Seitenkopf",
      "IIS Exam Page Title": "IIS-Prüfungsseitentitel",
      "IIS Exam Page": "IIS-Prüfungsseite",
      "Language Switch": "Sprachumschaltung",
      "Logged in username": "Angemeldeter Benutzername",
      "Switch to German language": "Zur deutschen Sprache wechseln",
      "Switch to English language": "Zur englischen Sprache wechseln",
      "Current language: English": "Aktuelle Sprache: Englisch",
      "Current language: German": "Aktuelle Sprache: Deutsch",
      "Notification bell": "Benachrichtigungsglocke",
      "Notifications list": "Benachrichtigungsliste",
      Notification: "Benachrichtigung",
      "proposed changes for": "vorgeschlagene Änderungen für",
      "approved changes in": "genehmigte Änderungen in",
      "rejected changes in": "abgelehnte Änderungen in",
      "added a new Exam": "hat eine neue Prüfung hinzugefügt",
      "No new notifications": "Keine neuen Benachrichtigungen",
      "Notifications button": "Benachrichtigungsschaltfläche",
      "unread notifications": "ungelesene Benachrichtigungen",
      "The following changes have been made": "Die folgenden Änderungen wurden vorgenommen",
      "The following exam details have been updated.": "Die folgenden Prüfungsdetails wurden aktualisiert.",
      "Error sending emails": "Fehler beim Senden von E-Mails",
      "Add New Record": "Neuen Datensatz hinzufügen",
      "Update Record": "Datensatz aktualisieren",
      Update: "Aktualisieren",
      "Cancel form": "Formular abbrechen",
      "Go to Exam Overview": "Zur Prüfungsübersicht gehen",
      "Go to Archived Exam Overview": "Zur archivierten Prüfungsübersicht gehen",
      "Go to Students Management": "Zur Studentenverwaltung gehen",
      "Go to Tutors Management": "Zur Tutorenverwaltung gehen",
      "Go to Data Administration": "Zur Datenverwaltung gehen",
      "Go to Room Management": "Zur Raumverwaltung gehen",
      Calendar: "Kalender",
      "Student Layout": "Studentenlayout",
      "Tutor Layout": "Tutorenlayout",
      "Go to Request New Exam": "Zur Anforderung einer neuen Prüfung gehen",
      "Go to Upcoming Monitored Exams": "Zu bevorstehenden betreuten Prüfungen gehen",
      "Upcoming Confirmed Exams": "Bevorstehende bestätigte Prüfungen",
      "Go to Upcoming/Registered Exams": "Zu bevorstehenden Tutoren-/Registrierten Prüfungen gehen",
      "Registered Exams": "Registrierte Prüfungen",
      "Go to Request Exam Monitoring": "Zur Anforderung der Prüfungsbetreuung gehen",
      "Vertical Calendar": "Vertikaler Kalender",
      "Admin dashboard main content": "Hauptinhalt des Admin-Dashboards",
      "Exam Management Application": "Prüfungsverwaltungsanwendung",
      "Application Root": "Anwendungsstamm",
      "Failed to send email": "Fehler beim Senden der E-Mail",
      Login: "Anmelden",
      "Submit Login Form": "Login-Formular absenden",
      Password: "Passwort",
      Email: "E-Mail",
      "Login Form": "Anmeldeformular",
      "Server can't be reached": "Server kann nicht erreicht werden",
      "Username or Password don't match.": "Benutzername oder Passwort stimmen nicht überein.",
      "Examiner added successfully": "Prüfer erfolgreich hinzugefügt",
      "Failed to save new examiner": "Neuer Prüfer konnte nicht gespeichert werden",
      "Both first and last name are required": "Vor- und Nachname sind erforderlich",
      "Select an examiner": "Wählen Sie einen Prüfer aus",
      "Select Examiner": "Prüfer auswählen",
      "Add New": "Neuen Eintrag hinzufügen",
      "Add New Examiner": "Neuen Prüfer hinzufügen",
      "Enter examiner first name": "Geben Sie den Vornamen des Prüfers ein",
      "Enter examiner last name": "Geben Sie den Nachnamen des Prüfers ein",
      "Enter examiner email": "Geben Sie die E-Mail-Adresse des Prüfers ein",
      "Examiner updated successfully": "Prüfer erfolgreich aktualisiert",
      "New Examiner": "Neuer Prüfer",
      "Current value": "Aktueller Wert",
      "Proposed value": "Vorgeschlagener Wert",
      "No examiner assigned": "Kein Prüfer zugewiesen",
      "proposed a new Exam": "hat eine neue Prüfung vorgeschlagen",
      //neu
      Occasional: "Gelegentlich",
      "Secondary Employment": "Nebenerwerb",
      "Not Necessary": "Nicht erforderlich",
      Presence: "Anwesenheit",
      Multimedia: "Multimedia",
      "Fernuni Hagen": "Fernuni Hagen",
      "Not anymore": "Nicht mehr",
      Study: "Studium",
      "Contract Type": "Vertragstyp",
      "Contract Completed": "Vertrag abgeschlossen",
      "In Distribution List": "In der Verteilerliste",
      "Salto Access": "Salto-Zugang",
      Location: "Standort",
      "Yes Until": "Ja bis",
      "Edit tutor Study": "Studium des Tutors bearbeiten",
      "Edit tutor contract type": "Vertragstyp des Tutors bearbeiten",
      "Edit tutor contract completed": "Vertragsabschluss des Tutors bearbeiten",
      "Edit tutor in distribution list value": "Wert des Tutors in der Verteilerliste bearbeiten",
      "Salto Access not granted": "Salto-Zugang nicht gewährt",
      "Date until Salto Access granted": "Datum, bis zu dem Salto-Zugang gewährt wird",
      "Tutor location": "Standort des Tutors",
      "Search Location...": "Standort suchen...",
      "Exam Status": "Prüfungsstatus",
      description: "Beschreibung",
      label: "Bezeichnung",
      "Matrikel Nr.": "Matrikelnummer",
      Disability: "Behinderung",
      "Presence/Multimedia": "Anwesenheit/Multimedia",
      "Select options...": "Optionen auswählen...",
      "Search...": "Suchen...",
      "No options found": "Keine Optionen gefunden",
      "Delete value": "Wert löschen",
      "Conditions Approved": "Bedingungen genehmigt",
      "Edit student condition approved value": "Genehmigte Bedingungen des Studenten bearbeiten",
      "Edit student in distribution list value": "Verteilerlistenwert des Studenten bearbeiten",
      "Edit student disability": "Behinderung des Studenten bearbeiten",
      "Edit student location": "Standort des Studenten bearbeiten",
      "Edit student presence or Multimedia value": "Anwesenheits- oder Multimedia-Wert des Studenten bearbeiten",
      "Disability Types": "Behinderungsarten",
      "Select disability types": "Behinderungsarten auswählen",
      "Edit student disability types": "Behinderungsarten des Studenten bearbeiten",
      Updates: "Aktualisierungen",
      "Edit student updates": "Updates für Studenten bearbeiten",
    },
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "en", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
